import { BlobServiceClient, BlockBlobClient } from '@azure/storage-blob';
import { ChangeEvent, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

export function handleFileUpload(selectedFile: File, sasTokenUrl: string, setErrorMessage: any): Promise<string | void> {
    const blobServiceClient = new BlobServiceClient(
        `https://kaylicolorstorageprod.blob.core.windows.net/?sv=2022-11-02&ss=bf&srt=co&sp=rwac&se=2040-01-04T09:54:24Z&st=2025-01-02T01:54:24Z&spr=https&sig=qTlFgve%2Bi271jewfv0kqm3h2Sh5q2vcuLRKm%2BHygLC4%3D`

    );
    console.log("Lance 25")
    console.log(selectedFile)
    var fileExt = selectedFile.name.split('.').pop();
    const containerClient = blobServiceClient.getContainerClient('request-images');
    const fileName = crypto.randomUUID().toString() + '.' + fileExt;
    console.log(fileName);
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    console.log(blockBlobClient.url);
    return blockBlobClient.uploadBrowserData(selectedFile, { maxSingleShotSize: 1000000 })
        .then((result: any) => {
            return blockBlobClient.url;
        })
        .catch((error: unknown) => {
            if (error instanceof Error) {
                const { message, stack } = error;
                setErrorMessage(`Failed to finish upload with error : ${message} ${stack || ''}`);
            } else {
                setErrorMessage(error as string);
            }
        });
};

