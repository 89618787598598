import React, { useEffect, useState } from 'react';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import { Ticket } from '../../models/Ticket';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { ColorAnalysisRequestState, ColorAnalysisRequestStateGroupings } from '../../models/ColorAnalysisRequestState';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import { Footer } from '../../components/Footer';
import Loading from '../../components/Loading';
import './RequestPayment.css';
import { ErrorLog } from '../../models/ErrorLog';

const RequestPayment: React.FC = () => {
  let navigate = useNavigate(); 
  let { email } = useParams();
  email = email?.toLowerCase();
  const [paymentMade, setPaymentMade] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [ticket, setTicket] = useState<Ticket>();
  const [issuePossible, setIssuePossible] = useState<boolean>(false);

  const initialOptions = {
    "clientId": "AfN2p44ik5gVfepvsXCPejJTd4rl3lzpCZ3YbJ6ai8bNYJ6lYsbRGe11IanDRB-f807wpx9WeGnQ-F9g",
    "enable-funding": "venmo",
    "disable-funding": "paylater,card",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const [message, setMessage] = useState("");

  useEffect(() => {
    KayliColorAPI.getTicket(email!)
      .then((response)=>{
        console.log(JSON.stringify(response.data))
        setTicket(response.data);
        setLoading(false);
        if(ColorAnalysisRequestStateGroupings.PaidStatuses().indexOf(response.data.status) > -1)
        {
          setPaymentMade(true);
        }
      })
      .catch(function (error) {
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  async function createOrder() {
    try {
      const response = await KayliColorAPI.createPaypalOrder(email!);
      if(response.data.status === "COMPLETED") {
        setPaymentMade(true);
      }
      else {
        return response.data.id;
      }
    } catch (error) {
      console.error(error);
      setIssuePossible(true);
      setMessage(`Could not initiate or complete PayPal Checkout...${error}`);
    }
  }

  async function completeOrder(data: any, actions: any) {
    try {
      //setLoading(true);
      let orderData = await KayliColorAPI.capturePaypalOrder(email!, data.orderID);
      //setLoading(false);
      console.log("Capture result orderData: " + JSON.stringify(orderData));
      
      if(orderData.data.status === "COMPLETED") {
        setPaymentMade(true);
        setMessage("");
      }
      else {
        setMessage("An issue occured with your payment. Please try again.");
        setIssuePossible(true);
        return actions.restart();
      }
    } catch (error) {
      setLoading(false);
      setIssuePossible(true);
      setMessage("An issue occured with your payment. Please try again.");
      return actions.restart();
    }
  }

  const onError = (err: any, type: string) => {
    setIssuePossible(true);
    let data = {
      email: email!.toLowerCase(), 
      data: JSON.stringify(err), 
      type: type,
      location: "",
      device: "",
      browser: ""
    } as ErrorLog;

    KayliColorAPI.logPaypalerror(data)
  }

  return (
    <div>
      <div className="centerIt paymentContainerTwo">
        <div className="paymentHeader">{ paymentMade ? `Payment Complete`: ``}</div>
        {(!paymentMade) && (<div className="homeBody">
          My standard fee is $30.
          <br/><br/>
          If you'd like to pay with card, just select "PayPal" below and then "Pay with Debit or Credit Card". No PayPal account necessary.
          <br/><br/>
          Thanks so much for supporting me! I'm excited to help you find your color season!
        </div>)}
        {(paymentMade) && (<div className="homeBody">
          Thank you so much for your support! You are in the queue for analysis.
          <br/>
          Keep an eye on your email for your results in the next 5-10 days.
          <br/>
          -Kayli
        </div>)}
        <Loading loading={loading} ></Loading>
        {(!loading && !paymentMade) && (
          <div className="App">
            <PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                style={{
                  shape: "pill",
                  layout: "vertical",
                  disableMaxWidth: true
                }}
                createOrder={createOrder}
                onApprove={completeOrder}
                onCancel={(err) => onError(err, "cancel")}
                onError={(err) => onError(err, "error")}
              />
            </PayPalScriptProvider>
          </div>
        )}
        <div className="errorMessage">{message}</div>
        {((issuePossible || (ticket?.paypalOrderIds?.length ?? 0) > 1)) && !paymentMade && (
          <div className="troublePaying">
            If you are having any issue with making a payment you can also go to this link to pay:<br/><br/>
            <a href="https://www.paypal.com/ncp/payment/N7LNJARMRLPA6">https://www.paypal.com/ncp/payment/N7LNJARMRLPA6</a><br/><br/>
            If you do, you must email me at kayli@colorsbykayli.com to let me know that you paid. Include your name and email that you used to sign up for this website. I will need to mark you as 'paid' manually.<br/><br/>
            If that link still does not work, you can try a different device or just email me for help. Thanks!
          </div>)
        }
      </div>
      <Footer></Footer>
    </div>
  );
};

export default RequestPayment;
