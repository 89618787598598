import { ColorFrame } from "./ColorFrame";
import { ColorFrameType } from "./ColorFrameType";
import { ColorSeasons } from "./ColorSeasons";

export const ColorFrames = {
    getAllColorFrames: function (): ColorFrame[] {
        let results: ColorFrame[] = [];

        //CoolSummer
        results.push(({
                relativeUrl: '../../CoolSummer-12Colors-v2.svg',
                relativeUrlJpg: '../../CoolSummer-12Colors-v2.jpg',
                season: ColorSeasons.CoolSummer,
                type: ColorFrameType.Colors,
                order: 1
            } as ColorFrame));
        results.push(({
            relativeUrl: '../../CoolSummer-12Neutrals-v2.svg',
            relativeUrlJpg: '../../CoolSummer-12Neutrals-v2.jpg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.Neutrals,
            order: 2
            } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-01c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-02c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-03c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-04c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-05c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-06c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-07c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-08c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-09c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-10c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-11c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-12c.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-01n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-02n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-03n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-04n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-05n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-06n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-07n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-08n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-09n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-10n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-11n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Summer-Single-12n.svg',
            season: ColorSeasons.CoolSummer,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //LightSummer
        results.push(({
            relativeUrl: '../../LightSummer-12Colors-v2.svg',
            relativeUrlJpg: '../../LightSummer-12Colors-v2.jpg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Lightsummer-12Neutrals-v2.svg',
            relativeUrlJpg: '../../Lightsummer-12Neutrals-v2.jpg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-01c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-02c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-03c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-04c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-05c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-06c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-07c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-08c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-09c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-10c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-11c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-12c.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-01n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-02n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-03n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-04n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-05n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-06n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-07n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-08n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-09n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-10n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-11n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Summer-Single-12n.svg',
            season: ColorSeasons.LightSummer,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //SoftSummer
        results.push(({
            relativeUrl: '../../SoftSummer-12Colors-v2.svg',
            relativeUrlJpg: '../../SoftSummer-12Colors-v2.jpg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../SoftSummer-12Neutrals-v2.svg',
            relativeUrlJpg: '../../SoftSummer-12Neutrals-v2.jpg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-01c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-02c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-03c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-04c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-05c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-06c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-07c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-08c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-09c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-10c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-11c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-12c.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-01n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-02n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-03n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-04n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-05n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-06n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-07n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-08n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-09n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-10n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-11n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Summer-Single-12n.svg',
            season: ColorSeasons.SoftSummer,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));
        

        //BrightWinter
        results.push(({
            relativeUrl: '../../ClearWinter-12Colors-v2.svg',
            relativeUrlJpg: '../../ClearWinter-12Colors-v2.jpg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../ClearWinter-12Neutrals-v2.svg',
            relativeUrlJpg: '../../ClearWinter-12Neutrals-v2.jpg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-01c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-02c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-03c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-04c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-05c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-06c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-07c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-08c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-09c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-10c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-11c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-12c.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-01n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-02n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-03n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-04n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-05n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-06n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-07n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-08n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-09n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-10n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-11n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Winter-Single-12n.svg',
            season: ColorSeasons.BrightWinter,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //DeepWinter
        results.push(({
            relativeUrl: '../../DeepWinter-12Colors-v3.svg',
            relativeUrlJpg: '../../DeepWinter-12Colors-v3.jpg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../DeepWinter-12Neutrals-v2.svg',
            relativeUrlJpg: '../../DeepWinter-12Neutrals-v2.jpg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-01c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-02c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-03c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-04c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-05c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-06c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-07c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-08c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-09c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-10c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-11c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-12c.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-01n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-02n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-03n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-04n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-05n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-06n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-07n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-08n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-09n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-10n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-11n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Winter-Single-12n.svg',
            season: ColorSeasons.DeepWinter,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //CoolWinter
        results.push(({
            relativeUrl: '../../CoolWinter-12Colors-v2.svg',
            relativeUrlJpg: '../../CoolWinter-12Colors-v2.jpg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../CoolWinter-12Neutrals-v2.svg',
            relativeUrlJpg: '../../CoolWinter-12Neutrals-v2.jpg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-01c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-02c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-03c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-04c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-05c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-06c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-07c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-08c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-09c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-10c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-11c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-12c.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-01n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-02n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-03n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-04n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-05n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-06n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-07n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-08n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-09n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-10n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-11n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Cool-Winter-Single-12n.svg',
            season: ColorSeasons.CoolWinter,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //BrightSpring
        results.push(({
            relativeUrl: '../../ClearSpring-12Colors-v2.svg',
            relativeUrlJpg: '../../ClearSpring-12Colors-v2.jpg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../ClearSpring-12Neutrals-v2.svg',
            relativeUrlJpg: '../../ClearSpring-12Neutrals-v2.jpg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-01c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-02c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-03c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-04c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-05c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-06c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-07c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-08c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-09c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-10c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-11c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-12c.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-01n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-02n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-03n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-04n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-05n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-06n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-07n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-08n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-09n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-10n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-11n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Clear-Spring-Single-12n.svg',
            season: ColorSeasons.BrightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //LightSpring
        results.push(({
            relativeUrl: '../../LightSpring-12Colors-v2.svg',
            relativeUrlJpg: '../../LightSpring-12Colors-v2.jpg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../LightSpring-12Neutrals-v2.svg',
            relativeUrlJpg: '../../LightSpring-12Neutrals-v2.jpg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-01c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-02c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-03c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-04c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-05c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-06c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-07c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-08c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-09c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-10c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-11c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-12c.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-01n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-02n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-03n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-04n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-05n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-06n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-07n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-08n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-09n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-10n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-11n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Light-Spring-Single-12n.svg',
            season: ColorSeasons.LightSpring,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //WarmSpring
        results.push(({
            relativeUrl: '../../WarmSpring-12Colors-v2.svg',
            relativeUrlJpg: '../../WarmSpring-12Colors-v2.jpg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../WarmSpring-12Neutrals-v2.svg',
            relativeUrlJpg: '../../WarmSpring-12Neutrals-v2.jpg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-01c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-02c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-03c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-04c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-05c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-06c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-07c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-08c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-09c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-10c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-11c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-12c.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-01n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-02n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-03n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-04n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-05n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-06n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-07n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-08n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-09n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-10n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-11n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Spring-Single-12n.svg',
            season: ColorSeasons.WarmSpring,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //DeepAutumn
        results.push(({
            relativeUrl: '../../DeepAutumn-12Colors-v2.svg',
            relativeUrlJpg: '../../DeepAutumn-12Colors-v2.jpg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../DeepAutumn-12Neutrals-v2.svg',
            relativeUrlJpg: '../../DeepAutumn-12Neutrals-v2.jpg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-01c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-02c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-03c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-04c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-05c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-06c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-07c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-08c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-09c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-10c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-11c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-12c.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-01n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-02n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-03n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-04n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-05n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-06n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-07n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-08n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-09n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-10n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-11n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Deep-Autumn-Single-12n.svg',
            season: ColorSeasons.DeepAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //SoftAutumn
        results.push(({
            relativeUrl: '../../SoftAutumn-12Colors-v2.svg',
            relativeUrlJpg: '../../SoftAutumn-12Colors-v2.jpg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../SoftAutumn-12Neutrals-v2.svg',
            relativeUrlJpg: '../../SoftAutumn-12Neutrals-v2.jpg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-01c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-02c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-03c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-05c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-06c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-07c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-08c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-09c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-10c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-11c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-12c.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-01n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-02n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-03n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-04n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-05n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-06n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-07n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-08n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-09n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-10n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-11n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Soft-Autumn-Single-12n.svg',
            season: ColorSeasons.SoftAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        //WarmAutumn
        results.push(({
            relativeUrl: '../../WarmAutumn-12Colors-v2.svg',
            relativeUrlJpg: '../../WarmAutumn-12Colors-v2.jpg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.Colors,
            order: 1
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../WarmAutumn-12Neutrals-v2.svg',
            relativeUrlJpg: '../../WarmAutumn-12Neutrals-v2.jpg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.Neutrals,
            order: 2
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-01c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 3
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-02c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 4
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-03c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 5
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-04c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 6
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-05c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 7
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-06c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 8
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-07c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 9
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-08c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 10
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-09c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 11
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-10c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 12
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-11c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 13
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-12c.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidColor,
            order: 14
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-01n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 15
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-02n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 16
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-03n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 17
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-04n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 18
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-05n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 19
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-06n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 20
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-07n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 21
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-08n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 22
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-09n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 23
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-10n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 24
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-11n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 25
        } as ColorFrame));
        results.push(({
            relativeUrl: '../../Warm-Autumn-Single-12n.svg',
            season: ColorSeasons.WarmAutumn,
            type: ColorFrameType.SolidNeutral,
            order: 26
        } as ColorFrame));

        return results;
    }
}